exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-thanks-js": () => import("./../../../src/pages/about/thanks.js" /* webpackChunkName: "component---src-pages-about-thanks-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-press-page-js": () => import("./../../../src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../../../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-republishing-page-js": () => import("./../../../src/templates/republishing-page.js" /* webpackChunkName: "component---src-templates-republishing-page-js" */),
  "component---src-templates-stories-page-js": () => import("./../../../src/templates/stories-page.js" /* webpackChunkName: "component---src-templates-stories-page-js" */),
  "component---src-templates-stories-sub-page-js": () => import("./../../../src/templates/stories-sub-page.js" /* webpackChunkName: "component---src-templates-stories-sub-page-js" */)
}

